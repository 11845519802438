.title1 {
    // font-weight: 700;
    // font-size: 48px;
    // line-height: 56px;
    font-size:calc(1.5rem + 1.5vw);
}

.title2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
}

.title3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;

    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 28px;
    }
    @media (max-width: 640px) {
        font-size: 18px;
        line-height: 26px;
    }
}

.large1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
}

.large2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;

    @media (max-width: 768px) {
        font-size: 16px;
        line-height: 16px;
    }

    @media (max-width: 640px) {
        font-size: 14px;
        line-height: 14px;
    }



}

.large3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    @media screen and (max-width: 768px) {
        font-size: 16px;
        line-height: 16px;    
    }
    @media screen and (max-width: 640px) {
        font-size: 14px;
        line-height: 14px;
    }
}

.large_tight1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
}

.large_tight2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
}

.large_tight3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
}

.large_normal1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
}

.large_normal2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
}

.large_normal3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}

.regular {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
}

.regular2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
}

.regular3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
}

.regular_normal1 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.regular_normal2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.regular_normal3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 14px;
    }
    @media (max-width: 640px) {
        font-size: 12px;
        line-height: 12px;
    }

}

.small1 {
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
}

.small2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
}

.small3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
}

.small_tight_1 {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
}

.small_tight2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
}

.small_tight3 {

    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.small_normal1 {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}

.small_normal2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.small_normal3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.tiny1 {
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
}

.tiny2 {
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
}

.tiny3 {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
}

.tiny_tight1 {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
}

.tiny_tight2 {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
}

.tiny_tight3 {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.tiny_normal1 {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
}

.tiny_normal2 {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
}

.tiny_normal3 {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}