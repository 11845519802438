// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

@use "~@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// importing custom styles
@import "./app/styles/custom-styles.scss";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$hurrey-platform-primary: mat.define-palette(mat.$indigo-palette);
$hurrey-platform-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$hurrey-platform-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$hurrey-platform-theme: mat.define-light-theme(
  (
    color: (
      primary: $hurrey-platform-primary,
      accent: $hurrey-platform-accent,
      warn: $hurrey-platform-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($hurrey-platform-theme);

/* You can add global styles to this file, and also import other style files */
// angular calender css
@import "../node_modules/angular-calendar/css/angular-calendar.css";

html,
body {
  height: 100%;
  // scroll bar styles
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background: "";
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    // background: #6B4EFF;
    border-radius: 10px;
    height: 3px;
  }
  ::-webkit-scrollbar-thumb:hover {
    // background: #7B61FF;
    border-radius: 10px;
  }
}
body {
  // margin: 0;
  max-width: 1800px;
  margin: auto !important;
  // font-family: Roboto, "Helvetica Neue", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;900&display=swap");

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "~tippy.js/dist/tippy.css";
@import "~tippy.js/themes/light.css";
@import "~tippy.js/animations/scale.css";

@import "./app/styles/components/buttons.scss";
@import "./app/styles/components/dialog.scss";
@import "./app/styles/fonts.scss";
@import "./app/Theme/typo.scss";
@import "./app/Theme/animation.scss";

// swipper js
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

.blue-snackbar {
  background: #2196f3;
  color: #fff;
}

.white-snackbar {
  background: #fff;
  color: black;
}
.mat-list-option {
  font-family: Nunito;
}
.spacer {
  flex: 1 1 auto;
}

// .animate {
//   @apply transition-all duration-500 ease-in;
// }

// md -14px
// lg - 18px
// xl-20px
// 2xl - 24px
// 3xl - 30px

li {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}
.c_primary {
  color: #307fe2;
}

.c_gold {
  color: #c28b00;
}
body {
  font-family: Nunito !important;
}

.card_default {
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(20, 20, 20, 0.08),
    0px 1px 8px 2px rgba(20, 20, 20, 0.08);
  border-radius: 16px;
}

::ng-deep .mat-progress-spinner.spin.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: red !important;
}

// // scroolbar
// ::-webkit-scrollbar-track {
//   // border: 1px solid green;
//   padding: 10px;
//   // background-color: #e4e4e4;
//   border-radius: 10px;
// }

// ::-webkit-scrollbar {
//   width: 7px;
// }

.class_custom_panel + * .cdk-overlay-pane > div.mat-menu-panel {
  // background: #FFFFFF;
  /* Large Shadow */

  box-shadow: 0px 1px 24px 8px rgba(20, 20, 20, 0.08);
  border-radius: 16px;
  width: 200px;
  padding: 5px;
  background: #f8efff;
  border: 0.5px solid #edd7ff;

  button {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height, or 100% */

    /* ink_base */

    color: #404446;
    display: flex;
    align-items: center;
    padding: 1em;

    &:hover {
      background: #e6e1ff;
      border-radius: 10px;
    }
  }
}

// date time picker css
@import "~ng-pick-datetime/assets/style/picker.min.css";

.planePanel {
  background-color: #f7f9fa;
  border-radius: 16px !important;
  box-sizing: border-box;
  display: block;
  max-width: none !important;
  padding: 0;
  min-height: 48px;
  transform-origin: center;
}
