.text_xxextralarge{
  font-size: 48px;
}
.text_extralarge{
    font-size:32px
}
.text_large{
    font-size:24px
}
.text_medium{
    font-size:18px
}
.text_small{
    font-size:14px
}
.text_Small{
    font-size:16px
}
.text_extrasmall{
    font-size:12px
}
