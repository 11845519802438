$primary_color: #6b4eff;
$secondary_color: #307fe2;
// default styles

//
// buttons
.btn_white {
  background-color: white;
  border-radius: 48px !important;
  border: 1px solid #8067ff !important;
  text-align: center;
  color: #307fe2;
  margin: 0px 8px;
  padding: 10px;
}

.btn_tranperent {
  background-color: transparent;
  border-radius: 48px !important;
  border: 1px solid #f7f9fa !important;
  text-align: center;
  color: #f7f9fa;
  margin: 0px 8px;
}

.btn_blue {
  background-color: #8067ff;
  border-radius: 48px !important;
  border: 1px solid #8067ff !important;
  text-align: center;
  color: #f7f9fa;
  margin: 0px 8px;
  padding: 10px;
}

.btn_blue_large {
  background-color: #8067ff;
  border-radius: 48px !important;
  border: 1px solid #8067ff !important;
  text-align: center;
  color: #f7f9fa;
  width: 300px;
  padding: 0.6em;
}

.btn_blue_small {
  background-color: #8067ff;
  border-radius: 48px !important;
  border: 1px solid #8067ff !important;
  text-align: center;
  color: #f7f9fa;
  width: 100px;
  padding: 0.6em;
}

.btn_blue_medium {
  background-color: #8067ff;
  border-radius: 48px !important;
  border: 1px solid #8067ff !important;
  text-align: center;
  color: #f7f9fa;
  min-width: 150px;
  padding: 0.6em;
}

.btn_red_medium {
  background-color: #ff585d;
  border-radius: 48px !important;
  border: 1px solid #ff585d !important;
  text-align: center;
  color: #f7f9fa;
  min-width: 150px;
  padding: 0.6em;
}

.btn_white_medium {
  background-color: white;
  border-radius: 48px !important;
  border: 1px solid #8067ff !important;
  text-align: center;
  color: #307fe2;
  min-width: 150px;
  padding: 0.6em;
}

.btn_white_large {
  background-color: white;
  border-radius: 48px !important;
  border: 1px solid #8067ff !important;
  text-align: center;
  color: #307fe2;
  width: 300px;
  padding: 0.6em;
}

.btn_blue_narrow_normal {
  background-color: #8067ff;
  border-radius: 48px !important;
  border: 1px solid #8067ff !important;
  text-align: center;
  color: #f7f9fa;
  width: 150px;
  padding: 0.6em;
}

.btn_blue_squre {
  background-color: #8067ff;
  color: #f7f9fa;
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  margin: 0px 8px;
  padding: 10px;
}

.btn_white_squre {
  background-color: white;
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  color: #8067ff;

  margin: 0px 8px;
  padding: 10px;
  border: 1px solid #947fff;
}

.btn_black_squre {
  background-color: white;
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  color: #303437;
  border: 1px solid #72777a;

  margin: 0px 8px;
  padding: 10px;
}

.btn_blue_white {
  // background-color: #E5E5E5;
  border: 1px solid #947fff;

  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  color: #6b4eff;
  margin: 0px 8px;
  padding: 10px;
}

.btn_black {
  border: 1px solid #cdcfd0;
  box-sizing: border-box;
  border-radius: 6px;
  color: #404446;
  text-align: center;
  padding: 10px;
}

.btn_bg_black {
  background-color: #303437;
  border-radius: 48px !important;
  // border: 1px solid #8067ff !important;
  text-align: center;
  color: #f7f9fa;
  margin: 0px 8px;
  padding: 10px;
}

.btn_blue_out_line {
  border: 1px solid #947fff;
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  color: #6b4eff;
  padding: 10px;
}

.btn_sky_base {
  border: 1px solid #cdcfd0;
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  color: #404446;
  padding: 10px;
}

// default card
.card_default {
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(20, 20, 20, 0.08), 0px 1px 8px 2px rgba(20, 20, 20, 0.08);
  border-radius: 16px;
}

.card_default1 {
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(20, 20, 20, 0.04), 0px 0px 8px rgba(20, 20, 20, 0.08);
}

.carousel_card {
  background: #ffffff;
  /* sky_base */

  border: 1px solid #cdcfd0;
  box-sizing: border-box;
  border-radius: 16px;
}

.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
}

.heading2 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
}

.heading3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.heading4 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.text {
  font-size: 14px;
  line-height: 20px;
  font-family: Nunito;
}

.text_light {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #6c7072;
}

.small_text {
  font-size: 12px;
  line-height: 14px;
  color: #76777e;
}

.text_blue {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #6c7072;
}

.horizontal_line {
  width: 100%;
  height: 1px;
  background-color: #cdcfd0;
}

.vertical_line {
  width: 1px;
  height: inherit;
  background-color: #cdcfd0;
}

.badge {
  background: #f5faff;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  /* Blue_base */

  color: #307fe2;
  padding: 1em;
}

.badge_yellow {
  // background: #fff8e6;
  border-radius: 33px;
  // text-align: center;
  // padding: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  /* yellow_darkest */

  color: #c28b00;
}

.badge_blue {
  // background: #f5faff;
  border-radius: 33px;
  // text-align: center;
  // padding: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  color: #5b93ff;
}

.link {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #6b4eff;
}

.bread_scrum {
  display: flex;
  align-items: center;
  // gap: 1em;
  margin-bottom: 1em;
}

// height: 3em;
//         width: 10em;

mat-form-field {
  width: 100%;
}

// back ground colors
.bg_green_lightest {
  background: #d1fae1;
}

.bg_red_lightest {
  background: #ffd5d4;
}

.bg_yellow_light {
  background: #fff1cc;
}

.bg_blue_light {
  background: #d6e8ff;
}

.bg_blue_base {
  background: #307fe2;
}

.bg_purple_base {
  background: #6b4eff;
}

.bg_yellow_darkest {
  background: #ffd66b;
}

.ng_ink_base {
  background: #404446;
}

.bg_red_base {
  background: #ff585d;
}

.bg_red_lightest {
  background: #ffd5d4;
}

.bg_sky_lighter {
  background: #f2f4f5;
}

.bg_sky_base {
  background: #cdcfd0;
}

.bg_green_base {
  background: #23c16b;
}

// colors
.color_green_base {
  color: #23c16b;
}

.color_red_base {
  color: #ff585d;
}

.color_yellow_dark {
  color: #f2ad00;
}

.color_yellow_darkest {
  color: #c28b00;
}

.color_blue_base {
  color: #307fe2;
}

.color_black {
  color: #000000;
}

.color_ink_light {
  color: #6c7072;
}

.color_ink_lighter {
  color: #72777a;
}

.color_white {
  color: white;
}

.colorsky_dark {
  color: #979c9e;
}

.color_ink_base {
  color: #404446;
}

.color_purple_base {
  color: #6b4eff;
}

.color_blur_light {
  color: #539cf8;
}

.color_blue_lightest {
  color: #c5dfff;
}

.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.no_data_full_page {
  height: 100%;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column;
  gap: 10px;
}

.events_view_dialog_style {
  mat-dialog-container {
    padding: 0px !important;
    border-radius: 20px !important;
  }
}

.yellow-progress {
  .mat-progress-bar-fill::after {
    background-color: #fdd463 !important;
  }

  &.mat-progress-bar {
    height: 10px;
    border-radius: 13px;
  }
}

.blue-progress {
  .mat-progress-bar-fill::after {
    background-color: #5e5af9 !important;
  }

  &.mat-progress-bar {
    height: 10px;
    border-radius: 13px;
  }
}

// fee management styles
.kyc-input {
  .mat-form-field {
    width: 400px !important;
  }
}

.kyc_verif_dialog {
  mat-dialog-container {
    // padding: 0px !important;
    border-radius: 20px !important;
  }
}

.create_fee_Structure {
  .mat-step-icon {
    display: none !important;
  }

  .mat-step-text-label {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mat-stepper-horizontal-line {
    border: 2px dashed #6b4eff;
  }
}

.fee_payment_type {
  .mat-radio-button {
    margin-bottom: 1em;
  }

  .mat-radio-outer-circle {
    height: 25px;
    width: 25px;
    border-width: 1px;
    border-color: #cdcfd0;
  }

  .mat-radio-inner-circle {
    height: 25px;
    width: 25px;
    border-width: 1px;
  }
}

.AssignSubjectTeacherDialog {
  mat-dialog-container {
    @media (max-width: 600px) {
      padding: 0px !important;
    }

    &::-webkit-scrollbar-track {
      // border: 1px solid green;
      // padding: 10px;
      background-color: white;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      // border-radius: 10px;
      // box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      // background-color: #307fe2;
      // border: 1px solid blue;
      border: 5px solid #e4e4e4;
    }
  }
}

.component_padding {
  width: 95%;
  // margin: auto;
  // padding-top: 2em;
  margin: auto;
  padding: 2em;

  @media screen and (max-width:768px) {
    padding: 0em;
  width: 100%;
  }
}

.teah_stud_list {
  mat-dialog-container {
    background: #ffffff;
    box-shadow: -1px -10px 32px rgba(0, 0, 0, 0.15);
    border-top-left-radius: 20px;

    // border-top-right-radius: 20px;
    &::-webkit-scrollbar-track {
      // border: 1px solid green;
      // padding: 10px;
      background-color: white;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      // border-radius: 10px;
      // box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      // background-color: #307fe2;
      // border: 1px solid blue;
      border: 5px solid #e4e4e4;
    }
  }
}

.hide_in_mobile {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.rounded_radius {
  mat-dialog-container {
    border-radius: 30px !important;
  }

}

.search_box {
  display: block;
  direction: rtl;

  .search_input {
    background: #dad3ff;
    border-radius: 30px;
    padding: 10px 10px 10px 35px;
    width: 100%;
    transition: all 0.5s ease-out 0s;
    direction: ltr;

    &:focus {
      outline: none;
    }

    // &:hover {
    //   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    //   border-radius: 30px;
    //   // transition: all 0.5s transform 6s;
    //   width: 500px;
    // }

    &::placeholder {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #947fff;
    }
  }

  // .search_input{
  //   width: 100%;
  //  transition: width 2s, transform 2s;

  //   &:hover {
  //     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  //     border-radius: 30px;
  //     // transition: width 2s, transform 2s;
  //     width: 500px;
  //   }

  // }
}



// mat modal
// full width in mobile view
.matdialog_responsive {
  mat-dialog-container {
    @media (max-width: 600px) {
      border-radius: 0px !important;
      width: 100vw;
    }
  }
}

.chip {
  background: #C5DFFF;
  border-radius: 50%;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropdownMenu+ * .cdk-overlay-pane > div.mat-menu-panel  {
  background-color: white;
  width: 300px !important;
  max-width: 500px !important;
  padding: 0px;
  border-radius: 5px;
  transition: all 0.5s;
  overflow: hidden;
  border-radius: 20px 20px 20px 20px;
  transform: all 0.5s;

  div.mat-menu-content{
    padding-top: 0px !important;
    height: 240px !important;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.5s;

  }
  button.mat-menu-item {
    border-radius: 10px;
    margin-top: 10px;
    height: auto;
    line-height: 0px;
    font-family: nunito;
    &:hover {
      // background-color: #e4e4e4;
      transform: scale(1.02)  !important;
    }
  }
}
