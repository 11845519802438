.prof-dialog {
  .mat-dialog-container {
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(20, 20, 20, 0.08),
      0px 1px 8px 2px rgba(20, 20, 20, 0.08);
    border-radius: 16px;
    padding: 36px !important;
    max-height: unset !important;
    font-family: Nunito !important;
    position: relative;
  }
  .mat-dialog-title {
  }
  .mat-dialog-content {
    font-family: Nunito !important;
    padding: 0px 54px !important;
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #e4e4e4;
      border-radius: 18px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 18px;
      background-color: #307fe2;
    }
    &::-webkit-scrollbar-track {
      max-height: 50%;
    }
  }
  .mat-dialog-actions {
    padding-right: 30px !important;
  }
  .close {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}

.sub-dailog {
  .mat-dialog-container {
    box-shadow: 0px 0px 1px rgba(20, 20, 20, 0.08),
      0px 1px 8px 2px rgba(20, 20, 20, 0.08);
    border-radius: 16px;
    padding: 36px;
    position: relative;
  }
}

@media only screen and (min-height: 500px) {
  .mat-dialog-container {
    max-height: 600px !important;
  }
}

.onboard-dialog {
  .mat-dialog-container {
    box-shadow: 0px 0px 1px rgba(20, 20, 20, 0.08),
      0px 1px 8px 2px rgba(20, 20, 20, 0.08);
    border-radius: 16px;
    padding: 32px 32px 60px 32px;
    position: relative;
  }
}

.onboard-alert {
  .mat-dialog-container {
    box-shadow: 0px 0px 1px rgba(20, 20, 20, 0.08),
      0px 1px 8px 2px rgba(20, 20, 20, 0.08);
    border-radius: 16px;
    padding: 32px 32px 60px 32px;
    position: relative;
  }
  .mat-dialog-title {
    margin-bottom: 32px;
  }
  .mat-dialog-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
    overflow: hidden !important;
  }
  .close {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
.addClassDialog {
  .mat-dialog-container {
    box-shadow: 0px 0px 1px rgba(20, 20, 20, 0.08),
      0px 1px 8px 2px rgba(20, 20, 20, 0.08);
    border-radius: 16px;
    // padding: 28px;
    position: relative;
  }
}

.mat-dialog-content {
  font-family: Nunito !important;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #e4e4e4;
    border-radius: 18px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 18px;
    background-color: #307fe2;
  }
  &::-webkit-scrollbar-track {
    max-height: 50%;
  }
}
