.big-blue-button-solid {
  background: linear-gradient(90deg, #6b4eff 0%, #307fe2 100%);
  border-radius: 48px;
  padding: 16px 130px;
  color: #f7f9fa;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  @media screen and (max-width: 768px) {
    padding: 12px 120px;
    font-size: 12px;
  }
}

.big-blue-button-outlined {
  text-align: center;
  border: 2px solid #6b4eff;
  box-sizing: border-box;
  border-radius: 48px;
  padding: 16px 130px;
  font-style: normal;
  text-align: center;
  font-family: Nunito;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  color: #6b4eff;
  @media screen and (max-width: 768px) {
    padding: 12px 120px;
    font-size: 12px;
  }
}

.small-blue-button-solid {
  background: linear-gradient(90deg, #6b4eff 0%, #307fe2 100%);
  border-radius: 48px;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 12px 30px;
  color: #f7f9fa;
}

.small-blue-button-outlined {
  display: flex;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding: 12px 30px;
  text-align: center;
  color: #6b4eff;
  border: 2px solid #6b4eff;
  box-sizing: border-box;
  border-radius: 48px;
}
